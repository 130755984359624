import React from 'react'

import Layout from '../components/layout'
import ButtonLink from '../components/ButtonLink'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import AmbianceLighting from '../images/c-class-coupe/highlights/ambiance-lighting.jpeg'
import NeverStopImproving from '../images/c-class-coupe/highlights/ambiance-lighting.jpeg'
import DynamicBodyControl from '../images/c-class-coupe/highlights/dynamic-body-control.jpeg'
import SportyDesign from '../images/c-class-coupe/highlights/sporty-design.jpeg'
import DigitalCockpit from '../images/c-class-coupe/highlights/digital-cockpit.jpeg'
import IntelligentDrive from '../images/c-class-coupe/highlights/intelligent-drive.jpeg'

import HeaderImage from '../images/c-class-coupe/header-c-class-coupe.jpeg'

import InterieurImage from '../images/c-class-coupe/gallery/interieur-teaser-c-class-coupe.jpeg'
import ExterieurImage from '../images/c-class-coupe/gallery/exterieur-teaser-c-class-coupe.jpeg'

import InterieurImageGallery1 from '../images/c-class-coupe/gallery/gallery1-interieur-c-class-coupe.jpeg'
import InterieurImageGallery2 from '../images/c-class-coupe/gallery/gallery2-interieur-c-class-coupe.jpeg'
import InterieurImageGallery3 from '../images/c-class-coupe/gallery/gallery3-interieur-c-class-coupe.jpeg'
import InterieurImageGallery4 from '../images/c-class-coupe/gallery/gallery4-interieur-c-class-coupe.jpeg'

import ExterieurImageGallery1 from '../images/c-class-coupe/gallery/gallery1-exterieur-c-class-coupe.jpeg'
import ExterieurImageGallery2 from '../images/c-class-coupe/gallery/gallery2-exterieur-c-class-coupe.jpeg'
import ExterieurImageGallery3 from '../images/c-class-coupe/gallery/gallery3-exterieur-c-class-coupe.jpeg'
import ExterieurImageGallery4 from '../images/c-class-coupe/gallery/gallery4-exterieur-c-class-coupe.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
  InterieurImageGallery4,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
  ExterieurImageGallery4,
]

const CClassCoupe = () => (
  <Layout>
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>Dynamik ist eine Haltung. Das neue C-Klasse Coupé.</H1>
      <p>
        In Bestform, wenn es drauf ankommt. Das neue C-Klasse Coupé ist da und
        zeigt sich durchtrainiert wie nie. <br />
        Rechtzeitig zum Sommer kann ab sofort das neue C-Klasse coupe bei den
        Händlern bestellt werden und mit ihm ein weiterer C-Klasse Zweitürer:
        das Coupé. Mit aktualisiertem Design, digitalem Cockpit und DYNAMIC BODY
        CONTROL Fahrwerk wurden Sportlichkeit und Fahrspaß gesteigert.
      </p>
      <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
    <Section align="default" variant="grey">
      <H1>
        Sich niemals auszuruhen, sondern Innovationskraft immer wieder neu zu
        beweisen.
      </H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={NeverStopImproving}
          title="Never Stop Improving"
          description=""
        />
        <ImageBox
          imagesrc={DynamicBodyControl}
          title="DYNAMIC BODY CONTROL Fahrwerk"
          description="Perfekt abgestimmt auf die Fahrleistungen eines Sportwagens ist das neue Fahrwerk DYNAMIC BODY CONTROL. Für ein sportliches Setup können Sie per Tastendruck eine sportliche Abstimmung wählen oder höchsten Komfort genießen."
        />
        <ImageBox
          imagesrc={SportyDesign}
          title="Sportliches Design"
          description="Es ist der Auftritt, der großen Sportlern ihren Glanz verleiht. Das neue C-Klasse Coupé in Bestform zeigt die AMG Line. Mit neuer Front- und Heckschürze präsentiert es sich durchtrainiert wie nie. Dazu faszinieren innovative MULTIBEAM LED- Scheinwerfer sowie neue Lackfaben: zum Beispiel das sportlich-markante Graphitgrau metallic."
        />
        <ImageBox
          imagesrc={DigitalCockpit}
          title="Digitales Cockpit"
          description="Das neue C-Klasse Coupé bedienen Sie so intuitiv und einfach wie ein Smartphone.  

          Ganz neu sind viele Bedien- und Anzeigeelemente: Das neue Lenkrad mit Touch Control Buttons sowie die je nach Ausstattung volldigitalen Instrumente und vergrößerten Displays, inklusive neuer Infotainment-Generation, ermöglichen eine höchst individuelle und moderne Bedienung. Alle Instrumente können Sie jetzt zudem ausstattungsabhängig in den Anzeigestilen „Klassisch“, „Sportlich“ und „Progressiv“ beleuchten und animieren lassen.  "
        />
        <ImageBox
          imagesrc={IntelligentDrive}
          title="Mercedes Benz Intelligent Drive"
          description="Ob Wiederanfahren im Stau, situationsgerechte Bremskraftunterstützung bei erkannter Kollisionsgefahr oder Distanzeinhaltung auf der Autobahn bei bis zu 210 km/h: Dabei kann das neue C-Klasse Coupé den Fahrer schon heute unterstützen. Einen zuverlässigeren und aufmerksameren Partner können Sie sich unterwegs kaum wünschen."
        />
        <ImageBox
          imagesrc={AmbianceLighting}
          title="Ambientebeleuchtung"
          description='Für jede Stimmung, für jede Tages- und Nachtzeit. Für jeden Anlass und jedes Mal anders: Die Ambientebeleuchtung verwandelt den Innenraum des neuen C-Klasse Coupés in eine faszinierende Lounge. 64 Farben können dazu einzeln angewählt werden. Die Ambientebeleuchtung ist auf Wunsch einzeln erhältlich oder als Bestandteil der optionalen ENERGIZING Komfortsteuerung. Die Ambientebeleuchtung mit 64 Farben, das Soundsystem und die AIR-BALANCE Beduftung werden miteinander vernetzt und können durch Programme wie "Vitalität" oder "Frische" die Leistungsfähigkeit und das Wohlbefinden steigern.'
        />
      </SimpleSlider>
    </Section>
    <TestDrive />
  </Layout>
)

export default CClassCoupe
